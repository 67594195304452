// Styles
import "./nav.css";

// Libraries
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// Components
import LangSelect from "../langSelect/LangSelect";

export default function Navbar() {
  const { t } = useTranslation(['home']);
  const [menuActive, setMenuActive] = useState(false);
  const [activeLink, setActiveLink] = useState("/");
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const toggleIcon = menuActive ? "menuIcon toggle" : "menuIcon";

  useEffect(() => {
    const handleScrolling = () => {
      if (menuActive) {
        document.body.style.overflow = "hidden";
        document.body.style.height = "390px";
      } else {
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
      }
    };

    handleScrolling();

    window.addEventListener("scroll", handleScrolling);

    return () => {
      window.removeEventListener("scroll", handleScrolling);
    };
  }, [menuActive]);

  return (
    <div>
      <nav id="navbar">
        <div className="nav-wrapper">
          <div className="logo">
            <a href="/">
              <img src="/images/logo.svg" alt="logo" />
            </a>
          </div>
          <ul id="menu">
            <li>
              <a href="/" className={activeLink === "/" ? "active" : ""}>
                {t("menu.home")}
              </a>
            </li>
            <li>
              <a
                href="/services"
                className={activeLink === "/services" ? "active" : ""}
              >
                {t("menu.services")}
              </a>
            </li>
            <li>
              <a
                href="/projects"
                className={activeLink === "/projects" ? "active" : ""}
              >
                {t("menu.projects")}
              </a>
            </li>
            <li>
              <a
                href="/about"
                className={activeLink === "/about" ? "active" : ""}
              >
                {t("menu.about")}
              </a>
            </li>
            <li>
              <a
                href="/contact"
                className={activeLink === "/contact" ? "active" : ""}
              >
                {t("menu.contact")}
              </a>
            </li>
            <li>
              <LangSelect />
            </li>
          </ul>
        </div>
      </nav>

      <div className={toggleIcon} onClick={toggleMenu}>
        <span className="icon icon-bars"></span>
        <span className="icon icon-bars overlay"></span>
      </div>

      <div className={`overlay-menu ${menuActive ? "active-overlay" : ""}`}>
        <ul>
          <li>
            <a href="/">{t("menu.home")}</a>
          </li>
          <li>
            <a href="/services">{t("menu.services")}</a>
          </li>
          <li>
            <a href="/projects">{t("menu.projects")}</a>
          </li>
          <li>
            <a href="/about">{t("menu.about")}</a>
          </li>
          <li>
            <a href="/contact">{t("menu.contact")}</a>
          </li>
          <li>
            <LangSelect />
          </li>
        </ul>
      </div>
    </div>
  );
}
