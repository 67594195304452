import { useEffect, useState } from "react";
import "./anker.css";

export default function Anker() {
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      {backToTopButton && (
        <button className="back-to-top-button" onClick={scrollUp}>
          <img src="/images/arrow.png" alt="xfuivefb"></img>
        </button>
      )}
    </div>
  );
}