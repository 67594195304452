import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import '@splidejs/react-splide/css/skyblue';
import "./footer.css";

export default function Footer() {
  const { t } = useTranslation(["home"]);
  return (
    <div className="footer-body">
      <div className="customers">
        <div className="text-row">
          <Fade>
            <h1>{t("customerTitle")}</h1>
          </Fade>
        </div>
        <Splide
          aria-label="our offers"
          options={{
            type: "loop",
            interval: 1500,
            autoplay: true,
            speed: 3000,
            perMove: 1,
            rewind: true,
            perPage: window.innerWidth <= 760 ? 2 : 5,
            width: "100%",
            arrows: false,
            pagination: false,
          }}
        >
          <SplideSlide>
            <img src="/images/logos/logo-elektrokrone.jpg" alt="homematic" />
          </SplideSlide>
          <SplideSlide>
            <img src="/images/logos/logo-anatomicus.png" alt="rarchitect" />
          </SplideSlide>
        </Splide>
      </div>
      <div className="footer">
        <div className="footer-left">
          <img src="/images/logo.svg" alt="footer-logo"></img>
          <article>
            <p>{t("footer.slogan")}</p>
          </article>
          <div className="social"></div>
        </div>
        <div className="footer-mid">
          <h1>{t("footer.inquiries")}</h1>
          <p>{t("footer.hesitate")}</p>
          <a href="/contact">
            <button className="clickhere">{t("allLinks.contactUs")}</button>
          </a>
        </div>
        <div className="footer-right">
          <h1>{t("allLinks.title")}</h1>
          <a href="/">{t("allLinks.home")}</a>
          <a href="/about">{t("allLinks.aboutUs")}</a>
          <a href="/services">{t("allLinks.services")}</a>
          <a href="/contact">{t("allLinks.contactUs")}</a>
        </div>
      </div>
      <div className="legal">
        <p>
          Copyright © 2024 teladesign.ch - <strong>{t("legal.rights")}</strong>
        </p>
        <div>
          <a href="/imprint">{t("allLinks.imprint")}</a>
        </div>
      </div>
    </div>
  );
}
