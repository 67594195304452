// Styles
import "./langselect.css";

// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

const languages = [
  { code: "en", lang: "English", flag: "/images/flags/en.png" },
  { code: "de", lang: "German", flag: "/images/flags/de.png" },
];

export default function LangSelect() {
  const { i18n } = useTranslation(["home", "services", "about", "contact", "impressum"]);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
    setCurrentLanguage(code);
    setTimeout(() => {
      window.location.reload();
    });
  };

  const otherLanguage = languages.find((lng) => lng.code !== currentLanguage);

  return (
    <div className="lang-container">
      <button
        className="lang-button"
        onClick={() => changeLanguage(otherLanguage.code)}>
        <img
          src={otherLanguage.flag}
          alt={`${otherLanguage.lang} flag`}
          className="flag-icon"
        />
      </button>
    </div>
  );
}
