// index.js
import "./index.css";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Components
import "./components/i18n";
import Anker from "./components/anker/anker";
import Progress from "./components/progressbar/progress";
import Loading from "./components/loading/loading";
import SideButton from "./components/sideButton/sidebutton";
import Footer from "./components/footer/footer";
import Nav from "./components/nav/nav";

// Pages
const Service = lazy(() => import("./app/service/service.jsx"));
const About = lazy(() => import("./app/about/about.jsx"));
const Contact = lazy(() => import("./app/contact/contact.jsx"));
const Projects = lazy(() => import("./app/projects/projects.jsx"));
const Imprint = lazy(() => import("./app/imprint/imprint.jsx"));

const Home = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./app/home/home.jsx")), 350);
  });
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={<Loading />}>
        <Nav />
        <Progress />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Service />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/imprint" element={<Imprint />} />
        </Routes>
        <Footer />
        <Anker />
        <SideButton />
      </Suspense>
    </Router>
  </React.StrictMode>
);
