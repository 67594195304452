import "./loading.css";

export default function loading() {
  return (
    <div>
      <div className="load_container">
        <div className="load">
        </div>
        <h1>Loading...</h1>
      </div>
    </div>
  );
}
